import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <p>{`Inputs come in two different colors. The default input color is `}<inlineCode parentName="p">{`$field-01`}</inlineCode>{` and is used on `}<inlineCode parentName="p">{`$ui-background`}</inlineCode>{` and `}<inlineCode parentName="p">{`$ui-02`}</inlineCode>{` page backgrounds. The `}<inlineCode parentName="p">{`--light`}</inlineCode>{` version input color is `}<inlineCode parentName="p">{`$field-02`}</inlineCode>{` and is used on `}<inlineCode parentName="p">{`$ui-01`}</inlineCode>{` page backgrounds.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color toke`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$field-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select--light`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$field-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select--inline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`transparent`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select--inline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select__arrow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "35.869565217391305%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAAAwUlEQVQoz62ROwuDQBCEz2wfCIH83JBOsLHW3jII/h5R8BAE3w/w0MLMHTkJKcwVGViGg+XbGY6xL3mep9x1Xeb7PhvHUc0wDGr0W0r7obZt2x1zyrKM0jSlqqpomiZq25Y0SB4wkoYeCXAzWFmWlvS+76+c82dRFFGe52HTNCEgUdd1ARLe3pWtn0BUUr6u6xmge5IkThzHNsA2oA4OPuAXuVPX9f8qCyHMYMuy7FAkIKQkVCd8ACE1zfNMekf7p169G5yyU0M8MgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Default and new selection state examples for Select",
          "title": "Default and new selection state examples for Select",
          "src": "/static/e2d7d9dcd456aa0837cf202d0d5a0c84/fb070/select-style-1.png",
          "srcSet": ["/static/e2d7d9dcd456aa0837cf202d0d5a0c84/d6747/select-style-1.png 288w", "/static/e2d7d9dcd456aa0837cf202d0d5a0c84/09548/select-style-1.png 576w", "/static/e2d7d9dcd456aa0837cf202d0d5a0c84/fb070/select-style-1.png 1152w", "/static/e2d7d9dcd456aa0837cf202d0d5a0c84/c3e47/select-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Examples of default and new selection select states</Caption>
    <h3 {...{
      "id": "interaction-states"
    }}>{`Interaction states`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$focus`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input[data-invalid]`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form-requirement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$support-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input:disabled`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$disabled-02`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Open:`}</strong>{` Style determined by browser`}</p>
    <p><strong parentName="p">{`Help text:`}</strong>{` Help text appears below the label when the input is active. Help text remains visible while the input is focused and disappears after focus away.`}</p>
    <p><strong parentName="p">{`Error:`}</strong>{` Error messages appear below the input field and are always present while invalid.`}</p>
    <p><strong parentName="p">{`Disabled:`}</strong>{` Disabled state has a `}<inlineCode parentName="p">{`.not-allowed`}</inlineCode>{` cursor hover.`}</p>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "136.54891304347828%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAbCAYAAAB836/YAAAACXBIWXMAAAsSAAALEgHS3X78AAAD7UlEQVRIx61Vz0tjVxS+72qM0TCOMJ1qC/0buuiqMNDpsnQ1DHTUjYgiohR3gkbbGi2GGFBE7aqoXZSkcfxBkSIDnc1MMYxDpvPy++XlJTGJMdH4kxAV3nz3YlJjkkHoHDh83JfzvnfO+c65IQaDgTAbGBggRqORnJ2dEa/XSwKBAJEk6dbOLI9kcHCQ/F8rkA0NDeUzpKOjo1pkSJEh9fv91O12VymKUuJ4maICyvDK/yOdmpriB7PZXD03N6dPJpMan89XEwopGqeqks++fCI8/t4kTNleCKbfngu/rG4JXn9Ag5c1IOUIF4qyZL27aYaRH+j62lqzqqoNlxfnDQFJapCCMvM7gaAiVCx5enqa4+TkJJmfnyexWIwH76fTHyNLl9vjjSnhcPgwk2Gu7O/vJyORyOcsZnd3l5YV5br9uBzkhD+vKY2GP+Sk8c+E+tN6TDWsp9WBp0nV+CynDtrkB7z/T6O0hGBiYoKjyWQiY2NjZPuNyAnD8ZTe5Qu+fOUUD956AnGfHEl4pHBMCseP/HL0CxaDRzxDNmJFGY6Pj5d86Nnv0yTkctxTvK+bY9K/TWd7clM2rTSlFLH5zYu/qiv28FqGGmR4D2o3bm5u6tCfmpyqUrecEF67gvS5QxT+3hKFUCorKOEIU1gLkhq4lo1QgfRmyRaLhWxsbBCPx0NAXA2VKVyAGFXRaLQKgrA5JBitkk3Jl162ZBjrZW2lzTg/Py9fcmtrK8e2tjbS0tJSGBtszP2dnZ1XmBgpFAr50un025OTE/Hw8PAl8BMWc3x8LJQdm87OzsKXUB7Hi4uLWpT3CEEtWMPv8KEnqVSqNZFIPAbWs5iDg4PSDPNk3d3dQnt7u7avr4/mSd9n8XicuFwu3reisVlaWuIHu91ehb2+u7q6WscUZuoB61G2HiXrIYgeJHp2lmWZKctiaq7UpkWijIyMfLjrC3PHcWZmhgwPDxOHw8HPGBPt9vb2tyjrG/TqK4jxEEJ9DUEenJ6ecvXxrPSC7ejoKPQSPSQQgKt8eXl5H0L8A5W9wWDQtbe3Jx4dHXnxoS0QfcpiMplMeZX7+/tLSkAvCdTUIas6iKRD73TItg7904miWLnknp4ejr29vbSrq+sOyGuZyk6nU6jULzSfZSbksShDm83GDysrK3RxcbEea1eLMjUoswYzV4ey9fB65kxlhtdVvvLiG3t2dvbDqWy1WjkuLy+ThYUFguyEK5Xvwi0QwgIRzFgzMxS2AI3wxveKct3wEifMZrONIPoVI2OHW0FsxW92kC0AP8rv8q3Sv3mTlDOQ3q4X+cBcLkdww1D8rVKIQ1EexW8UmdJ8TDnSd7BHP77ifzaIAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Open, disabled, and more information/help state examples for Select",
          "title": "Open, disabled, and more information/help state examples for Select",
          "src": "/static/8f52889fa807f8dd137c73c3288da4fc/fb070/select-style-3.png",
          "srcSet": ["/static/8f52889fa807f8dd137c73c3288da4fc/d6747/select-style-3.png 288w", "/static/8f52889fa807f8dd137c73c3288da4fc/09548/select-style-3.png 576w", "/static/8f52889fa807f8dd137c73c3288da4fc/fb070/select-style-3.png 1152w", "/static/8f52889fa807f8dd137c73c3288da4fc/c3e47/select-style-3.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Examples of open, disabled, and help select states</Caption>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Select text should be set in sentence case, with only the first word in a phrase and any proper nouns capitalized. Select text should be three words or less.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0. 75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select--inline`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-short-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--form-requirement`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <h3 {...{
      "id": "select"
    }}>{`Select`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40 / 2.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select__arrow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input:focus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2px`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33.28804347826087%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAABJUlEQVQoz2NgAIKL6qkgiuGYSRXDic51YPbaNQ8Z795/zvjly0fGt2/fMX79/Jnx+us3jP///wfLM0ZEMiDrRQEwwatKcQyPgfSRBQcY8AGx2GQMvTgNhYHpk0/IX7p0RwHoIuG///6L/fz2TfTup09iJSePyTEsnMgMUmO7cAF+wy5opbNcDG4WBLF3X74z4d6TJ/+fPX364tHjx2+/fP706vrL19+O3HlwbFPWVF6QmkVA7+P18gXNNMYLXrXM34HsfdsOyl5YsNnx0oy19hdmrbO/N3+r09kdxyz27zmuuT2oGezCBbgMxBUWdxkYmC4yMLBeZ2Bg2cTAwIYiCY0cQl5mOB/ZDmbv3XAQHqMwcO7/X8aD+04xrs6cAuav+P8PwyEA6WaR7C0pjPgAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for select",
          "title": "Structure and spacing measurements for select",
          "src": "/static/facaa1730b51f12e2458afd52a800717/fb070/select-style-2.png",
          "srcSet": ["/static/facaa1730b51f12e2458afd52a800717/d6747/select-style-2.png 288w", "/static/facaa1730b51f12e2458afd52a800717/09548/select-style-2.png 576w", "/static/facaa1730b51f12e2458afd52a800717/fb070/select-style-2.png 1152w", "/static/facaa1730b51f12e2458afd52a800717/c3e47/select-style-2.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Structure and spacing measurements for select | px / rem</Caption>
    <h3 {...{
      "id": "small-select"
    }}>{`Small Select`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select__arrow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "83.83152173913044%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAACjUlEQVQ4y51UQW8SQRR+DCygNlI994jx4EFuJnjzn/gDTKQxwdimBwoEaIkcaVoMRGICrQH1YNIYk0ZC8GLbUJbdnV1aCgg0rQgtNmrqOjPtErCFNH7Jy5vZmfnmzfveWwACu90OGjKZDJTLZZBlmRnGGKrVKtRqNTbWvv9rFMxbrVY2sdlsMDExgeAC5As8FAVBByOgkUIgEGDe7XYbgsGgpdFocJIkGUk0RonY7jY2aocErHCKLJM12UgI+k3fI1VVtXcLHRcKBZ2iKNRQu6GwiI8OapM/DioLCv/FTOclLCJyWEeNXExtMMph8K+22M0vPu4kXmb31VAiM07nT5ayw59MMTs7eyFhev27gfpEZjey8rmuuhdSFjp/OPd+OKHL5WLe4/HopqenzYuLi4bj42Mdz/Nov77DCI8a+MFBeetRbm3VtEfSgkUBkWf2GxOMPX1+fp4RhkIhPRHmRiwWu9rudLhifssorOe5r9slkxbFjljiMCaCyTLxmCNRcedEYbny+0fmkojFfVNV06g9mjDg9Xp75TMzM6OLPzsl37j32CTcnxx7CqDP8eJcTpBf5eCuSXUtweafI8CbhV7hDxQ2hc/nO3fjazCDCrdJbizo+eqHO2tF8da7y0TodDqZn5qaQg6H4zqJ8srJr9+I36ugsvoTbXTqve5RTtqI5A/JRAiZ+lPT03rsRRiPx9nmZDKJwuHwteXlZTPpXU4SRQ5LElciic+ufULxN28pESfjU0E0Uc7GaCDKaDQ6UhRSRmPdbnf8Ur2cSCSYT6VSEIlEIJ1OQ6VSAdLTUK/X2VNarVag3W6vaIfp2tC/zTAcHh5SQjgjtBDCm9pap9OB/0az2RwY9/9MLsJfMRq/TOrQtFsAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for small select",
          "title": "Structure and spacing measurements for small select",
          "src": "/static/d531c10af455fcd20886ebcf39f2f6a1/fb070/select-style-4.png",
          "srcSet": ["/static/d531c10af455fcd20886ebcf39f2f6a1/d6747/select-style-4.png 288w", "/static/d531c10af455fcd20886ebcf39f2f6a1/09548/select-style-4.png 576w", "/static/d531c10af455fcd20886ebcf39f2f6a1/fb070/select-style-4.png 1152w", "/static/d531c10af455fcd20886ebcf39f2f6a1/c3e47/select-style-4.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing measurements for small select | px / rem & examples of
  open and disabled small select states
    </Caption>
    <h3 {...{
      "id": "inline-select"
    }}>{`Inline select`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32 / 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select-input`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--select__arrow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left, padding-right`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.173913043478258%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsSAAALEgHS3X78AAAAgklEQVQY02NgwAJ6enrAdGFrHcOS9h0ocqeEEsD0RfVUBpJAS0UOnL1A4zrzXrY29gcMXiwg/gMGf9IMA7vw/3+G/0B27sTr6rq5O4zD6ne4tRRs0ASKMYHkSHIh2MBV/8Fsv2nHRSXrdyt4TL6gWNhwQAQsSqqBIOCaL4jgrCJeHwA4ri5EWbGNcwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for Inline Select",
          "title": "Structure and spacing measurements for Inline Select",
          "src": "/static/f07b10026001766e56c1b727e69222c5/fb070/select-style-6.png",
          "srcSet": ["/static/f07b10026001766e56c1b727e69222c5/d6747/select-style-6.png 288w", "/static/f07b10026001766e56c1b727e69222c5/09548/select-style-6.png 576w", "/static/f07b10026001766e56c1b727e69222c5/fb070/select-style-6.png 1152w", "/static/f07b10026001766e56c1b727e69222c5/c3e47/select-style-6.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing measurements for inline select (focused) | px / rem
    </Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      